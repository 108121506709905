<!--会员风采列表-->
<template>
  <div class="memberDeatilDiv">
    <el-row class="breadTop">
      <el-col>
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <div class="memberClass">
      <div class="notice-title">
        <span>会员风采</span>
      </div>
      <div class="styleContent">
        <div class="styleList" v-for="(item, index) in styleList" :key="index" @click="toDetail(item)">
          <div class="styleImg">
            <img :src="item.contentImg" alt="">
          </div>
          <div class="styleName">
            <div class="title">{{item.contentTitle}}</div>
          </div>
        </div>
      </div>
    </div>
    <!--分页-->
    <div class="page">
      <el-pagination
        :page-sizes="[12, 20, 50, 100]"
        :page-size="pageSize"
        :current-page.sync="page"
        @current-change="pageChange"
        @size-change="handleSizeChange"
        @prev-click="prevClick"
        @next-click="nextClick"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableTotal">
      </el-pagination>
    </div>
    <!--增加取消下方白条-->
    <div style="height: 10px"></div>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import axios from 'axios'
export default {
  name: "memberDetail",
  components: { topPathList },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '会员风采',
          path: '/member/memberDetail'
        },
      ],
      styleList: [],
      page: 1,
      tableTotal: 0,
      pageSize: 12,
      contentId: "",
      typeId: "",
    }
  },
  methods: {
    getMemberDetail() {
      let param = {
        id: "744966206007812096",
        current: this.page,
        size: this.pageSize
      }
      axios({
        method: "post",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/api/portal/graphicdisplay/graphicList',
        data: param
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.styleList = res.data.data.records
          this.tableTotal = res.data.data.total
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    // 条数变化
    handleSizeChange(val) {
      this.pageSize = val
      this.getMemberDetail();
    },
    pageChange(val) {
      this.page = val
      this.getMemberDetail();
    },
    // 上一页
    prevClick(val) {
      this.page = val
      this.getMemberDetail()
    },
    // 下一页
    nextClick(val) {
      this.page = val
      this.getMemberDetail()
    },

    toDetail(item) {
      let pathObj = this.pathObj;
      pathObj.push({
        name: '会员风采详情',
        path: '/details'
      })

      this.$router.push({
        path: '/details',
        query: {
          pathObj: pathObj,
          contentId: item.contentId,
          typeId: '744966206007812096'
        }
      });
    }
  },
  mounted() {
    this.getMemberDetail()
  }
}
</script>

<style scoped lang="less">
  .memberDeatilDiv {
    text-align: center;
    background-color: #f8f9fa !important;
  }
  .breadTop {
    width: 1200px;
    margin: auto;
  }
  .memberClass {
    background: #FFFFFF;
    width: 1200px;
    margin: 30px auto;
    overflow: hidden;
    .notice-title {
      clear: both;
      font-size: 35px;
      font-weight: bold;
      margin-top: 20px;
    }
    .styleContent {
      min-height: 409px;
      width: 1200px;
      margin: 30px auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      .styleList {
        margin: 20px;
        img{
          width: 360px;
          height: 260px;
        }
        .styleName {
          width: 360px;
          font-size: 20px;
          font-weight: 500;
          color: #000000;
          .title {

          }
        }
      }
    }
  }
  .page {
    margin: 20px;
  }

</style>
